var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('kanban-board',{key:_vm.kanbanRenderKey,staticClass:"kanban mb-2",attrs:{"stages":_vm.stagesArray,"blocks":_vm.dataFormatted},on:{"update-block":_vm.updateTaskStage}},[_vm._l((_vm.fields[_vm.options.stage_field]['options']),function(stage){return _c('div',{key:stage,class:{
        'can-change-status': _vm.canChangeStatus,
      },attrs:{"slot":stage},slot:stage},[_c('div',{staticClass:"card shadow-0"},[_c('div',{staticClass:"card-body p-2"},[_c('div',{staticClass:"font-weight-bold m-0 d-flex"},[_c('div',{},[_vm._v(_vm._s(_vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.render.data) + ".fields." + (_vm.options.stage_field) + ".options." + stage))))])])])])])}),_vm._l((_vm.dataFormatted),function(block){return _c('div',{key:block.id,attrs:{"slot":block.id},slot:block.id},[_c('a',{class:{
          'card mb-2 p-2 text-secondary': true,
          'cursor-pointer': _vm.canChangeStatus,
        },attrs:{"href":'#' + block.id}},[_c('div',[(_vm.options.category_field && block.type)?_c('span',{staticClass:"w-auto mb-1 badge badge-info text-left"},[_vm._v(" "+_vm._s(_vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + (_vm.render.data) + ".fields." + (_vm.options.category_field) + ".options." + (block.type))))+" ")]):_vm._e()]),_c('div',{staticClass:"title-field font-weight-semibold"},[_vm._v(_vm._s(block[_vm.config.name_field]))]),(_vm.options.render_fields && _vm.options.render_fields.length > 0)?_c('div',{staticClass:"small text-lightslate"},_vm._l((_vm.options.render_fields),function(field){return (block[field])?_c('div',{staticClass:"render-field"},[_vm._v(" "+_vm._s(block[field])+" "),(_vm.fields[field] && _vm.fields[field].display_after)?_c('span',[_vm._v(_vm._s(_vm.fields[field].display_after))]):_vm._e()]):_vm._e()}),0):_vm._e(),(_vm.options.actions_field && block[_vm.options.actions_field] && block[_vm.options.actions_field].length > 0)?_c('div',{staticClass:"mt-1 small"},_vm._l((block[_vm.options.actions_field]),function(action){return _c('div',[_vm._v(" — "),_c('span',{class:{
                'text-striked': action.done,
              }},[_vm._v(" "+_vm._s(action.name)+" ")])])}),0):_vm._e(),(
          (_vm.options.date_field && block[_vm.options.date_field]) || (_vm.options.user_field && block[_vm.options.user_field])
        )?_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('div',{staticClass:"small text-grey"},[(_vm.options.date_field && block[_vm.options.date_field])?_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(block[_vm.options.date_field]), 'shortText'))+" ")]):_vm._e()]),_c('div',[(_vm.options.user_field && block[_vm.options.user_field] && _vm.users[block[_vm.options.user_field]])?_c('div',[_c('el-avatar',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.users[block[_vm.options.user_field]].full_name,
                  placement: 'top',
                }),expression:"{\n                  content: users[block[options.user_field]].full_name,\n                  placement: 'top',\n                }"}],staticClass:"ml-1",attrs:{"size":"xsmall","src":_vm.users[block[_vm.options.user_field]].avatar.thumb}})],1):_vm._e()])]):_vm._e()])])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }