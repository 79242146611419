<template>

  <div>
    <div class="card">
      <img v-if="data.image" :src="data.image.urls.full" class="card-img-top" :alt="data.title">
      <div class="card-body">
          <div class="post-content">
          <h1 class="card-title mb-1">{{ data.title }}</h1>
          <p class="small text-grey">{{ $d(new Date(data.created_at), 'short') }}</p>
          <p class="card-text" v-html="data.body"></p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Viewmode from './_Viewmode'

export default {
  extends: Viewmode,
  name: 'ModulePost',
  props: ['data', 'fields', 'module', 'entity', 'page'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped>
.post-content {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
</style>
