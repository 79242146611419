import _ from 'lodash'

export default {
  props: [
    'data',
    'dataForm',
    'loading',
    'errors',
    'submitForm',
    'fields',
    'config',
    'module',
    'entity',
    'page',
    'mediasToRemove',
  ],
  // props: {
  //   mode: {
  //     type: String,
  //     default: 'edit',
  //     validator: function (value) {
  //       return ['edit', 'view'].indexOf(value) !== -1
  //     }
  //   },
  // },
  mounted () {

  },
  methods: {
    displayField (fieldKey) {
      let fieldData = this.fields[fieldKey]
      if (!fieldData.conditions) {
        return true
      }

      let operator_table = {
          '==': (a, b) => { return a == b },
          '===': (a, b) => { return a === b },
          '!=': (a, b) => { return a != b },
          '!==': (a, b) => { return a !== b },
          '<': (a, b) => { return a < b },
          '<=': (a, b) => { return a <= b },
          '>': (a, b) => { return a > b },
          '>=': (a, b) => { return a >= b },
      }

      let displayFieldReturn = true
      fieldData.conditions.forEach(condition => {
        if(
          !operator_table[condition.operator](this.dataForm[condition.field], condition.value)
        ) {
            displayFieldReturn = false
            return false
        }
      })

      // If no display : remove value
      if (!displayFieldReturn) {
        // @todo
      }

      // If all conditions are OK
      return displayFieldReturn
    },
    resetForm () {
      this.$nextTick(() => {
        this.$refs.moduleForm.reset()
      })
    },
    getFormFieldKey (fieldKey) {
      return `module-form-field-${fieldKey}`
    },
  },
  computed: {
  },
};
