export default {
  props: ['data', 'fields', 'module', 'entity', 'page', 'config'],
  // props: {
  //   mode: {
  //     type: String,
  //     default: 'edit',
  //     validator: function (value) {
  //       return ['edit', 'view'].indexOf(value) !== -1
  //     }
  //   },
  // },
  methods: {
    displayField (fieldKey) {
      let fieldData = this.fields[fieldKey]
      if (!fieldData.conditions) {
        return true
      }

      let operator_table = {
          '==': (a, b) => { return a == b },
          '===': (a, b) => { return a === b },
          '!=': (a, b) => { return a != b },
          '!==': (a, b) => { return a !== b },
          '<': (a, b) => { return a < b },
          '<=': (a, b) => { return a <= b },
          '>': (a, b) => { return a > b },
          '>=': (a, b) => { return a >= b },
      }

      let displayFieldReturn = true
      fieldData.conditions.forEach(condition => {
        if(
          !operator_table[condition.operator](this.data[condition.field], condition.value)
        ) {
            displayFieldReturn = false
            return false
        }
      })

      // If no display : remove value
      if (!displayFieldReturn) {
        // @todo
      }

      // If all conditions are OK
      return displayFieldReturn
    },
  },
};
