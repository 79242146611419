export default {
  props: [
    'options',
    'data',
    'dataFiltered',
    'module',
    'entity',
    'page',
    'render',
    'fields',
    'config',
  ],
  // props: {
  //   mode: {
  //     type: String,
  //     default: 'edit',
  //     validator: function (value) {
  //       return ['edit', 'view'].indexOf(value) !== -1
  //     }
  //   },
  // },
};
