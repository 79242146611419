<template>
  <div>

    <div class="row">
      <div v-for="(post, postIndex) in dataFiltered" :class="
        postIndex == 0 ?
          'col-12' :
          postIndex <= 1 ?
            'col-md-6' :
            postIndex <= 2 ?
              'col-md-6' :
              'col-md-4'
      ">
        <div class="card">
          <a :href="'#' + post.id"><img v-if="post.image" :src="postIndex == 0 ? post.image.urls.full : post.image.urls.large" class="bg-light card-img-top" :alt="post.title"></a>
          <div class="card-body">
            <a :href="'#' + post.id"><h3 class="card-title mb-1 text-dark">{{ post.title }}</h3></a>
            <p class="small text-grey">{{ $d(new Date(post.created_at), 'short') }}</p>
            <p class="card-text" v-if="post.body">{{ post.body.replace(/<[^>]*>?/gm, '').replace(/^(.{100}[^\s]*).*/, "$1") }} ...</p>
            <a :href="'#' + post.id" class="btn btn-outline-secondary">{{ $t(`modules.${$route.params.module}.read_more`) }} <i class="icon-arrow-right8"></i></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {
  MODULE_SET_DATA_FORM,
} from '../../../store/mutations-types'


import Index from './_Index'

export default {
  extends: Index,
  name: 'ModuleIndexPosts',
  computed: {

  },
  methods: {
    editData (row) {
      this.$store.commit('module/' + MODULE_SET_DATA_FORM, {...row})
      this.openModal('module-data-form')
    },
    deleteData (row) {
      this.$store.commit('module/' + MODULE_SET_DATA_FORM, {...row})
      this.openModal('module-data-delete-confirm')
    },
  }
}
</script>
<style lang="scss" scoped>
.card-img-top {
  width: 100%;
  height: 250px;
  max-height: 250px;
  object-fit: cover;
}
</style>

