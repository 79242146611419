<template>

  <div>
    <div class="card card-content editor" id="comments">
      <div class="card-header">
        <loader v-if="loadingComments" />
        <el-card-title icon="icon-comments" v-else :title="$tc('comments.title_count', commentsCount, {count: commentsCount})" />
      </div>
      <div class="card-body">
        <div v-if="!noComments">
          <div v-for="comment in comments" class="mb-3">
            <div class="d-flex align-content-center justify-content-between" v-if="users[comment.user_id]">
              <div class="d-flex align-content-center">
                <el-avatar :src="users[comment.user_id].avatar.thumb" />
                <div class="ml-2">
                  <div class="font-weight-bold">{{ renderUsername(users[comment.user_id]) }}</div>
                  <div class="small text-grey">{{ $t('common.ago', {time: timeAgo(comment.created_at)}) }}</div>
                </div>
              </div>
              <div v-if="!archived && editedComment != comment.id && comment.user_id == currentUser.id">
                <div class="d-inline-block ml-2">
                  <i @click="editedCommentText = comment.comment; editedComment = comment.id" class="icon-pencil text-grey cursor-pointer" v-tooltip="$t('common.edit')" />
                </div>
                <div class="d-inline-block ml-2">
                  <i v-if="loadingDeleteComment != comment.id" class="icon-trash-alt text-danger cursor-pointer" v-tooltip="$t('common.dbl_click_delete')" @dblclick="deleteComment(comment.id)" />
                  <loader v-else/>
                </div>
              </div>
            </div>
            <div class="mt-1 ml-3">
              <div v-if="editedComment != comment.id" v-html="nl2br(comment.comment)" />
              <form v-else-if="!archived" @submit.prevent="editComment">
                <TextareaAutosize
                  v-model="editedCommentText"
                  :required="true"
                  class="form-control"
                />
                <div class="text-right mt-2">
                  <span @click="editedComment = false; editedCommentText = false" class="cursor-pointer text-grey small mr-3">{{ $t('common.cancel') }}</span>
                  <button :disabled="!editedCommentText || editedCommentText == ''" type="submit" class="btn btn-success btn-sm rounded" v-if="!loadingSendComment">
                    {{ $t('comments.edit_comment_button') }}
                  </button>
                  <loader v-else/>
                </div>
              </form>
            </div>
          </div>
        </div>
        <form @submit.prevent="sendComment" v-if="!archived">
          <TextareaAutosize
            v-model="newComment"
            :required="true"
            class="form-control"
            :placeholder="$t('comments.new_comment_placeholder')"
          />
          <div class="text-right mt-2">
            <button :disabled="!newComment || newComment == ''" type="submit" class="btn btn-success btn-sm rounded" v-if="!loadingSendComment">
              {{ $t('comments.new_comment_button') }}
            </button>
            <loader v-else/>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script>

import _ from 'lodash'
import moment from 'moment'

import {
  MODULE_COMMENT_INDEX,
  MODULE_COMMENT_STORE,
  MODULE_COMMENT_UPDATE,
  MODULE_COMMENT_DESTROY,
} from '@/store/mutations-types'

export default {
  name: 'Comments',
  props: {
    archived: {
      required: true,
      type: Boolean,
    },
    module: {
      required: true,
      type: String,
    },
    entityType: {
      required: true,
      type: String,
    },
    entityId: {
      required: true,
      type: Number,
    },
  },
  data () {
    return {
      editedComment: false,
      editedCommentText: '',
      loadingSendComment: false,
      loadingUpdateComment: false,
      loadingDeleteComment: null,
      newComment: '',
      comments: null,
    }
  },
  mounted () {
    this.loadComments()
  },
  methods: {
    loadComments () {
      this.$store.dispatch('module/' + MODULE_COMMENT_INDEX, {
        worldId: this.world.id,
        moduleId: this.module,
        entityType: this.entityType,
        entityId: this.entityId,
      }).then(response => {
        this.comments = response
      })
    },
    deleteComment (commentId) {
      this.loadingDeleteComment = commentId
      this.$store.dispatch('module/' + MODULE_COMMENT_DESTROY, {
        worldId: this.world.id,
        moduleId: this.module,
        entityType: this.entityType,
        entityId: this.entityId,
        commentId: commentId,
      }).then(response => {
        if (response) {
          this.$delete(this.comments, _.findIndex(this.comments, ['id', commentId]))
        }
        this.loadingDeleteComment = null
      }).catch(error => {
        this.loadingDeleteComment = null
        this.notifError(error)
      })
    },
    sendComment () {
      if (!this.newComment || this.newComment == '') {
        return
      }
      this.loadingSendComment = true
      this.$store.dispatch('module/' + MODULE_COMMENT_STORE, {
        worldId: this.world.id,
        moduleId: this.module,
        entityType: this.entityType,
        entityId: this.entityId,
        comment: this.newComment,
      }).then(response => {
        this.newComment = ''
        this.loadingSendComment = false
        this.comments.push(response)
      }).catch(error => {
        this.loadingSendComment = false
        this.notifError(error)
      })
    },
    editComment () {
      if (!this.editedComment || !this.editedCommentText || this.editedCommentText == '') {
        return
      }
      this.loadingUpdateComment = true
      this.$store.dispatch('module/' + MODULE_COMMENT_UPDATE, {
        worldId: this.world.id,
        moduleId: this.module,
        entityType: this.entityType,
        entityId: this.entityId,
        commentId: this.editedComment,
        comment: this.editedCommentText,
      }).then(response => {
        this.$set(this.comments, _.findIndex(this.comments, ['id', this.editedComment]), response)
        this.editedComment = false
        this.editedCommentText = ''
        this.loadingUpdateComment = false
      }).catch(error => {
        this.loadingUpdateComment = false
        this.notifError(error)
      })
    },
    timeAgo(time) {
      return moment(time).toNow(true);
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    world () {
      return this.$store.getters['auth/getWorld']
    },
    users () {
      let users = this.$store.getters['world/getUsers'] ? _.keyBy(this.$store.getters['world/getUsers'], 'id') : [];
      return users
    },
    loadingComments () {
      return this.comments === null
    },
    commentsCount () {
      return this.comments ? this.comments.length : 0
    },
    noComments () {
      return !this.comments || this.comments.length == 0
    },

  }
}
</script>

<style scoped>
</style>
