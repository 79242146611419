<template>

  <div>
    <div class="card card-content editor">
      <div class="card-header pb-0">
        <h5 class="mb-0 content-title font-weight-semibold">
          <span class="d-inline-block display-title">{{ data[nameFieldKey] }}</span>
        </h5>
      </div>
      <div class="card-body editor__content">

        <fragment :key="fieldKey" v-for="(fieldConfig, fieldKey, fieldIndex) in fields">
          <div
            class="my-2"
            v-if="
              displayField(fieldKey) &&
              fieldConfig.type != 'info' &&
              (
                (fieldConfig.type == 'heading' && headingHasFilledFields(fieldKey)) ||
                data[fieldKey] &&
                (data[fieldKey] != '' || (fieldConfig.display_modes && fieldConfig.display_modes.view === true)) &&
                fieldKey !== nameFieldKey
              )
          ">

            <label
              v-if="fieldConfig.type != 'heading' && fieldConfig.type != 'formpart' && fieldConfig.type != 'checkbox'"
              class="font-weight-bold text-dark"
              >
              {{
                $te(`modules.${$route.params.module}.entities.${entity}.fields.${fieldKey}.title`) ? $t(`modules.${$route.params.module}.entities.${entity}.fields.${fieldKey}.title`) : $t(`modules.${$route.params.module}.entities.${entity}.fields.${fieldKey}`)
              }}
            </label>

            <container
              :entity="data"
              :entityType="entity"
              :index="fieldIndex"
              v-if="!fieldConfig.multiple"
              :id="fieldKey"
              :config="fieldConfig"
              :module="module"
              :required="fieldConfig.required ? true : false"
              :type="fieldConfig.type"
              :value="data[fieldKey]"
              :is="`module-form-field-${fieldConfig.type}`"
              mode="view"
            >
              Invalid form field type : <strong>{{ fieldConfig.type }}</strong>
            </container>

          <div v-else> <!-- multiple -->

              <div class="mb-2" v-for="(value, valueIndex) in data[fieldKey].length">
                <container
                  :entity="data"
                  :entityType="entity"
                  :index="fieldIndex"
                  :id="fieldKey"
                  :config="fieldConfig"
                  :module="module"
                  :required="fieldConfig.required ? true : false"
                  :type="fieldConfig.type"
                  :value="data[fieldKey][valueIndex]"
                  :is="`module-form-field-${fieldConfig.type}`"
                  mode="view"
                >
                  Invalid form field type : <strong>{{ fieldConfig.type }}</strong>
                </container>
              </div>

            </div> <!-- end multiple -->
          </div>
        </fragment>
      </div>
    </div>

  </div>

</template>

<script>

import _ from 'lodash'

import Viewmode from './_Viewmode'
import moduleFields from '@/components/module/fields'

export default {
  extends: Viewmode,
  name: 'ModuleItem',
  components: moduleFields,
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    headingHasFilledFields (heading) {
      if (!this.fields) {
        return false
      }
      return _.filter(this.fields, (field, fieldKey) => {
        return field.heading == heading && field.type != "heading" && this.data[fieldKey] && this.data[fieldKey] != ''
      }).length > 0
    },
  },
  computed: {
    nameFieldKey () {
      return this.config.name_field
    },
  }
}
</script>

<style scoped>
.content-title {
  line-height: 2.3em;
  font-size: 1.1em;
}
img {
  width: auto;
}
</style>
