let indexes = {
    'module-index-table': require('@/components/module/indexes/Table.vue').default,
    'module-index-posts': require('@/components/module/indexes/Posts.vue').default,
    'module-index-places': require('@/components/module/indexes/Places.vue').default,
    'module-index-tree': require('@/components/module/indexes/Tree.vue').default,
    'module-index-kanban': require('@/components/module/indexes/Kanban.vue').default,
    'module-index-calendar': require('@/components/module/indexes/Calendar.vue').default,
}

export default indexes
