<template>
  <div>
    <el-table
      :total="Object.keys(data).length"
      :columns="columnsFormatted"
      :data="dataFormatted"
    >

      <div slot="actions" slot-scope="{row}">
      </div>

    </el-table>
  </div>
</template>

<script>

import Index from './_Index'

export default {
  extends: Index,
  name: 'ModuleIndexTable',
  computed: {
    users () {
      return this.$store.getters['world/getUsers']
    },
    usersByKey () {
      return _.keyBy(this.users, 'id')
    },
    columnsFormatted () {
      if (!this.options.columns) {
        return {}
      }
      let columns = {}
      this.options.columns.forEach( element => {
        columns[element] = this.$te(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${element}.title`) ? this.$t(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${element}.title`) : this.$t(`modules.${this.$route.params.module}.entities.${this.entity}.fields.${element}`)
      })
      return columns
    },
    dataFormatted () {
      if (!this.dataFiltered) {
        return {}
      }
      let data = []
      this.dataFiltered.forEach( (row, rowIndex) => {
        // Format fields
        _.each(row, (fieldData, fieldKey) => {
          if (this.fields[fieldKey] && this.fields[fieldKey].type == 'user') {
            this.dataFiltered[rowIndex][fieldKey] = this.usersByKey[fieldData] ? this.usersByKey[fieldData].full_name : ''
          }
          else if (this.fields[fieldKey] && this.fields[fieldKey].type == 'reference') {
            let foundIndex = _.findIndex(this.fields[fieldKey].options, e => { return e.value == this.dataFiltered[rowIndex][fieldKey] })
            if (!foundIndex != -1) {
              this.dataFiltered[rowIndex][fieldKey] = this.fields[fieldKey].options[foundIndex] ? this.fields[fieldKey].options[foundIndex].title : this.dataFiltered[rowIndex][fieldKey]
            }
          }
          else if (this.fields[fieldKey] && this.fields[fieldKey].type == 'select') {
            this.dataFiltered[rowIndex][fieldKey] = this.$te(`modules.${this.$route.params.module}.entities.${this.module.pages[this.$route.params.page].render.data}.fields.${fieldKey}.options`) ?
             this.$t(`modules.${this.$route.params.module}.entities.${this.module.pages[this.$route.params.page].render.data}.fields.${fieldKey}.options.${this.dataFiltered[rowIndex][fieldKey]}`) :
             this.$t(`modules.${this.$route.params.module}.entities.${this.module.pages[this.$route.params.page].render.data}.fields.${fieldKey}.${this.dataFiltered[rowIndex][fieldKey]}`)
          }
        })

        // Add link
        row.link = this.$route.path + '#' + row.id
        data.push(row)
      })


      return data
    }
  },
  methods: {
  }
}
</script>
