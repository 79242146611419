var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-content editor"},[_c('div',{staticClass:"card-header pb-0"},[_c('h5',{staticClass:"mb-0 content-title font-weight-semibold"},[_c('span',{staticClass:"d-inline-block display-title"},[_vm._v(_vm._s(_vm.data[_vm.nameFieldKey]))])])]),_c('div',{staticClass:"card-body editor__content"},_vm._l((_vm.fields),function(fieldConfig,fieldKey,fieldIndex){return _c('fragment',{key:fieldKey},[(
            _vm.displayField(fieldKey) &&
            fieldConfig.type != 'info' &&
            (
              (fieldConfig.type == 'heading' && _vm.headingHasFilledFields(fieldKey)) ||
              _vm.data[fieldKey] &&
              (_vm.data[fieldKey] != '' || (fieldConfig.display_modes && fieldConfig.display_modes.view === true)) &&
              fieldKey !== _vm.nameFieldKey
            )
        )?_c('div',{staticClass:"my-2"},[(fieldConfig.type != 'heading' && fieldConfig.type != 'formpart' && fieldConfig.type != 'checkbox')?_c('label',{staticClass:"font-weight-bold text-dark"},[_vm._v(" "+_vm._s(_vm.$te(("modules." + (_vm.$route.params.module) + ".entities." + _vm.entity + ".fields." + fieldKey + ".title")) ? _vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + _vm.entity + ".fields." + fieldKey + ".title")) : _vm.$t(("modules." + (_vm.$route.params.module) + ".entities." + _vm.entity + ".fields." + fieldKey)))+" ")]):_vm._e(),(!fieldConfig.multiple)?_c(("module-form-field-" + (fieldConfig.type)),{tag:"container",attrs:{"entity":_vm.data,"entityType":_vm.entity,"index":fieldIndex,"id":fieldKey,"config":fieldConfig,"module":_vm.module,"required":fieldConfig.required ? true : false,"type":fieldConfig.type,"value":_vm.data[fieldKey],"mode":"view"}},[_vm._v(" Invalid form field type : "),_c('strong',[_vm._v(_vm._s(fieldConfig.type))])]):_c('div',_vm._l((_vm.data[fieldKey].length),function(value,valueIndex){return _c('div',{staticClass:"mb-2"},[_c(("module-form-field-" + (fieldConfig.type)),{tag:"container",attrs:{"entity":_vm.data,"entityType":_vm.entity,"index":fieldIndex,"id":fieldKey,"config":fieldConfig,"module":_vm.module,"required":fieldConfig.required ? true : false,"type":fieldConfig.type,"value":_vm.data[fieldKey][valueIndex],"mode":"view"}},[_vm._v(" Invalid form field type : "),_c('strong',[_vm._v(_vm._s(fieldConfig.type))])])],1)}),0)],1):_vm._e()])}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }